import React, { Component } from "react";
import {ControlledEditor} from "./components/ControlledEditor"
import {DailyShow} from "./components/EditorTemplates";
import AlertMessage from "./components/AlertMessage";
import { EditorState } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {apiGatewayEndpoint} from "./config";
import axios from "axios";
import { ProgressBar } from 'react-bootstrap';

declare function sendExceptionCWR(error: Error, fileName: string, lineNum: number): void;

class Upload extends Component {

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            uploadDetails: {
                "url": "",
                "fields": {
                    "key": "",
                    "AWSAccessKeyId": "",
                    "x-amz-security-token": "",
                    "policy": "",
                    "signature": ""
                }
            },
            showDetails: {
                title: "",
                description: "",
                shortDescription: "",
                airDate: "",
                tags: [],
                public: false
            },
            fileToUpload: "",
            alert: {
                variant: "",
                message: ""
            },
            uploadPercent: 80,
            editorState: EditorState.createEmpty()
        };

        this.fetchSignedURLAndUpload = this.fetchSignedURLAndUpload.bind(this);
        this.uploadFileToS3 = this.uploadFileToS3.bind(this);
        this.updateShowDetailField = this.updateShowDetailField.bind(this);
        this.handleSetVideoUpload = this.handleSetVideoUpload.bind(this);
        this.getFileName = this.getFileName.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.updateContentState = this.updateContentState.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.updateShortDescription = this.updateShortDescription.bind(this);
        this.updateAirDate = this.updateAirDate.bind(this);
        this.updatePublic = this.updatePublic.bind(this);
        this.updateFilePrefix = this.updateFilePrefix.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
    };

    getFileName(){
        if (this.state.fileToUpload !== ""){
            return this.state.fileToUpload.name;
        } else {
            return "Please choose a file to upload..."
        }
    }

    updateShowDetailField(fieldName: String, value){
        this.setState({
            showDetails: {
                ...this.state.showDetails,
                [fieldName]: value
            }
        })
    }

    updateContentState(contentState) {
        this.setState({
            contentState: contentState
        })
        this.updateShowDetailField("description", stateToHTML(contentState))
    }

    updateProgress(percent) {
        this.setState({uploadPercent: percent});
    }

    updateTitle(e){
        this.updateShowDetailField("title", e.target.value);
    }

    updateShortDescription(e){
        this.updateShowDetailField("shortDescription", e.target.value);
    }

    updateFilePrefix(e){
        this.updateShowDetailField("filePrefix", e.target.value);
    }

    updateAirDate(e){
        this.updateShowDetailField("airDate", e.target.value);
    }

    updatePublic(e){
        this.updateShowDetailField("public", e.target.checked)
    }

    isFormValid(){
        return this.state.fileToUpload !== "" &&
            this.state.showDetails.title !== "" &&
            this.state.showDetails.description !== "" &&
            this.state.showDetails.airDate !== "" &&
            this.state.showDetails.description !== ""
    }

    handleSetVideoUpload(event){
        this.setState({
            fileToUpload: event.target.files[0]
        })
    }

    async uploadFileToS3(fileUri, uploadDetails) {
        console.info("Starting to upload file")
        let error = false;
        let responseCode = -1;
        const formData = new FormData();
        for ( let key in uploadDetails.fields ) {
            formData.append(key, uploadDetails.fields[key]);
        }
        formData.append("file", fileUri);
        axios ({
            method: "post",
            url: uploadDetails.url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                console.log( `${loaded}kb of ${total}kb | ${percent}%` ) // just to see whats happening in the console
                if(percent <= 100) {
                    this.updateProgress(percent);
                }
            },
        }).then(response => {
            if(response.status !== 204) error = true;
            responseCode = response.status;
            return response.statusText;
        }).then(result => {
            if(error){
                let e = new Error ();
                e.message = `HTTP/${responseCode} when uploading file to S3: ${result}`
                // eslint-disable-next-line no-restricted-globals
                sendExceptionCWR(e, location.pathname, 131);
                throw(e);
            }
            this.setState({
                alert:{
                    variant: "success",
                    message: `Your submission has been processed successfully!`
                }
            });
        }).catch(e => {
            let oldErr = e;
            e.message = `Exception when uploading file to S3: ${e.message}`
            // eslint-disable-next-line no-restricted-globals
            sendExceptionCWR(e, location.pathname, 30);
            this.setState({
                alert: {
                    variant: "danger",
                    message: `An error occurred while uploading the video: ${oldErr}`
                }
            });
            throw(e);
        });
    }

    fetchSignedURLAndUpload = () => {
        let error = false;
        let responseCode = -1;
        fetch(`${apiGatewayEndpoint}/upload`,
            {method: "POST",
                body: JSON.stringify(
                    this.state.showDetails
                ),
                credentials: 'include'
            })
            .then(response => {
                if(!response.ok) error = true;
                responseCode = response.status;
                return response.json();
            })
            .then(result => {
                if(error) throw new Error (`HTTP/${responseCode}: ${JSON.stringify(result.error)}`)
                this.setState({
                    uploadDetails: result
                })
                this.setState({
                    alert:{
                        variant: "info",
                        message: `Your submission is pending upload. Please do not close this window!`
                    }
                });
                this.uploadFileToS3(this.state.fileToUpload, result)
            })
            .catch(e => {
                let oldErr = e;
                e.message = `Exception when uploading file to S3: ${e.message}`
                console.error(e);
                // eslint-disable-next-line no-restricted-globals
                sendExceptionCWR(e, location.pathname, 30);
                this.setState({
                    alert:{
                        variant: "danger",
                        message: `An error occurred while uploading the video: ${oldErr}`
                    }
                });
            });
    };

    render() {
        if (this.state.uploadDetails.url === ""){
            return (
                <div style={{marginTop: "3em", marginBottom: "3em"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Upload New Video</h2>
                                <hr />
                                <AlertMessage {...this.state.alert} />
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="showTitle" style={{ fontWeight:"bold" }}>Title</label>
                                        <input type="text" className="form-control" id="showTitle"
                                               aria-describedby="titleHelp" onChange={this.updateTitle}/>
                                        <br />
                                        <label htmlFor="showShortDescription" style={{ fontWeight:"bold" }}>Short Description</label>
                                        <input type="text" className="form-control" id="showShortDescription"
                                               aria-describedby="showShortDescriptionHelp" onChange={this.updateShortDescription}/>
                                        <small id="showShortDescriptionHelp" className="form-text text-muted">This is used when showing basic information about a show, for example on the homepage (Limit 50 characters)</small>
                                        <br />
                                        <label htmlFor="showTitle" style={{ fontWeight:"bold" }}>Show Date</label>
                                        <br />
                                        <input type="date" id="showDate" name="showDate" onChange={this.updateAirDate}/>
                                        <br />
                                        <br />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="publicCheckbox" style={{ fontWeight:"bold" }}>Public Video</label>
                                                <br />
                                                <input type="checkbox" value="" id="publicCheckbox" onChange={this.updatePublic}/>
                                            </div>
                                            <div className="col-md-8">
                                                <label htmlFor="filePrefix" style={{ fontWeight:"bold" }} hidden={!this.state.showDetails.public} >Public Video File Prefix</label>
                                                <input type="text" className="form-control" id="filePrefix"
                                                       onChange={this.updateFilePrefix} hidden={!this.state.showDetails.public}
                                                       placeholder="For example, christmas_raffle_2021"
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <label htmlFor="showDescription" style={{ fontWeight:"bold" }}>Show Description</label>
                                        <ControlledEditor id="showDescription" defaultContent={`${DailyShow}`} updateContentState={this.updateContentState}/>
                                        <br />
                                        <label htmlFor="fileUploadGroup" style={{ fontWeight:"bold" }}>Upload File</label>
                                        <div className="input-group mb-3" id="fileUploadGroup">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="fileUpload" onChange={this.handleSetVideoUpload} />
                                                <label className="custom-file-label" htmlFor="inputGroupFile01">{this.getFileName()}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <button type='button' className="btn btn-primary" onClick={this.fetchSignedURLAndUpload} disabled={!this.isFormValid()}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div style={{marginTop: "3em", marginBottom: "3em"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Upload New Video</h2>
                                <hr />
                                <AlertMessage {...this.state.alert} />
                                <br />
                                <ProgressBar now={this.state.uploadPercent} label={`${this.state.uploadPercent}%`} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Upload;