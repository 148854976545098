import Carousel from 'react-bootstrap/Carousel'
import React, {useState} from "react";
const headerImagePrefix = "/images/header"

export default function HomepageCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const itemInterval = 5000;

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item interval={itemInterval}>
                <div className="container">
                    <img
                        className="d-block img-fluid"
                        src={`${headerImagePrefix}/jelly_roll_header.jpg`}
                        alt="First slide"
                    />
                </div>
                <Carousel.Caption>
                    <p className={"transparentTitle"}><b>Rapper and Singer "Jelly Roll" with Farrell TV Crew Members</b></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
                <div className="container">
                    <img
                        className="d-block img-fluid"
                        src={`${headerImagePrefix}/header_2.jpg`}
                        alt="Second slide"
                    />
                </div>
                <Carousel.Caption>
                    <p className={"transparentTitle"}><b>Robin Roberts, "Good Morning America" with Farrell TV Crew Members</b></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
                <div className="container">
                    <img
                        className="d-block img-fluid"
                        src={`${headerImagePrefix}/header_3.jpg`}
                        alt="Second slide"
                    />
                </div>
                <Carousel.Caption>
                    <p className={"transparentTitle"}><b>Farrell TV's First Interview: Regis Philbin with John Lavelle</b></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
                <div className="container">
                    <img
                        className="d-block img-fluid"
                        src={`${headerImagePrefix}/header_4.jpg`}
                        alt="Second slide"
                    />
                </div>
                <Carousel.Caption>
                    <p className={"transparentTitle"}><b>Farrell TV Crew Members on the set of ABC's "State of the Union"</b></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
                <div className="container">
                    <img
                        className="d-block img-fluid"
                        src={`${headerImagePrefix}/class24_header.jpg`}
                        alt="Second slide"
                    />
                </div>
                <Carousel.Caption>
                    <p className={"transparentTitle"}><b>Farrell TV Crew: Class of 2024</b></p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}
