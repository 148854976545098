import React, { Component } from "react";
import HomepageCarousel from "./components/HomepageCarousel";
import ShowCardList from "./components/show-cards/show-card-list";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import fbn_logo from "./images/FBN_Logo.svg";

class Home extends Component {


    render() {
        return (
            <div>
                <header>
                    <HomepageCarousel/>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-5">
                            <h2>What We Do</h2>
                            <hr />
                            <p>Farrell TV, now in its Twenty-sixth Season, is the Broadcasting Center of Monsignor Farrell High School in Staten Island, NY.</p>
                            <p>The studio, operated by Farrell Students and Executive Producer Brother Paul Hannon, produces five “live” shows each week. The shows consist of school news, sports updates (including interviews with Farrell athletes), comical skits, "Stupid News", as well as interviews with celebrities, politicians, and professional athletes. </p>
                            <p>The Broadcasting Center consists of two fully functioning studios: the main studio, dedicated by Regis Philbin and Bill Evans from ABC-TV in 2000, and the second, “Studio B” which was opened in 2007. That same year a newly expanded edit suite was constructed and later upgraded in 2012 in partnership with MSG Varsity to produce sports and non-sports videos.</p>
                        </div>
                        <div className="col-md-4 mb-5">
                            <h2>Contact Us</h2>
                            <hr/>
                            <div>
                                <img width={"100%"} src={fbn_logo} alt="FarrellTV Logo"/>
                            </div>
                            <address style={{textAlign: "center"}}>
                                <strong>Farrell Television</strong>
                                <br/>2900 Amboy Road
                                <br/>Staten Island, NY 10306
                                <hr />
                                <small>Phone </small>718-987-2900 x1246
                                <br />
                            </address>
                        </div>
                    </div>
                    <h2>Recently Aired Episodes</h2>
                    <small><i>Some episodes are restricted and require a valid Monsignor Farrell Office365 Account to access. These restricted videos are marked with a <FontAwesomeIcon icon={faUserLock} /> icon.</i></small>
                    <hr/>
                    <ShowCardList numToShow="4"/>
                </div>
            </div>
        );
    }
}

export default Home;
