import React, { Component } from "react";
import ShowCard from "./show-card";
import {apiGatewayEndpoint} from '../../config'
import axios from "axios";
import AlertMessage from "../AlertMessage";

declare function sendExceptionCWR(error: Error, fileName: string, lineNum: number): void;

export default class ShowCardList extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showList: [],
            numToShow: this.props.numToShow || 10,
            alert: {
                variant: "",
                message: ""
            },
        }
        this.fetchShows = this.fetchShows.bind(this);
        this.fetchShows();
    }

    fetchShows = () => {
        axios ({
            method: "get",
            url: `${apiGatewayEndpoint}/shows`
        }).then(response => {
            return response.data;
        }).then(result => {
            this.setState({showList: result, alert: {variant: "", message: ""}});
        }).catch(e => {
            let oldErrMsg = e.message;
            e.message = `Exception when while fetching the show list: ${e.message}`
            // eslint-disable-next-line no-restricted-globals
            sendExceptionCWR(e, location.pathname, 30);
            this.setState({
                alert: {
                    variant: "danger",
                    message: `An error occurred while fetching the show list: ${oldErrMsg}`
                }
            });
        });
    };

    render() {
        if (this.state.alert.message){
            return (
                <div className="col-lg-12">
                    <AlertMessage {...this.state.alert} />
                </div>
            )
        } else if (this.state.showList !== undefined && this.state.numToShow !== undefined && this.state.showList.length > 0){
            return(
                <div className="row">
                    {this.state.showList.slice(0,this.state.numToShow).map(function(index, i) {
                        return (
                            <div className="col-lg-3 col-md-6 mb-4" key={i}>
                                <div className="card">
                                    <ShowCard info={index} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    }
}