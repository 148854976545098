import React, { Component } from "react";
import ArticleCard from "./components/article-card";
import {articleList} from "./configs/news";

class News extends Component {
    render() {
        document.title = "Recent News";
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" style={{marginTop: "3em", marginBottom: "3em"}}>
                        <div>
                            <h2>Recent News</h2>
                            <hr />
                        </div>
                        <div className="row">
                            {articleList.sort((a, b) => b.publishedDate.localeCompare(a.publishedDate)).map(function(index, i) {
                                return (
                                    <div className="col-lg-3 col-md-6 mb-4" key={i}>
                                        <div className="card">
                                            <ArticleCard info={index} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default News;