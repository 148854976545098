import React, { Component } from "react";
import AlertMessage from "./components/AlertMessage";

class Login extends Component {

    constructor(props: P, context: any) {
        super(props, context);
        const urlParams = new URLSearchParams(this.props.location.search)
        this.state = {
            otpToken: urlParams.get("otp") ? urlParams.get("otp") : ''
        }

        this.updateOTPToken = this.updateOTPToken.bind(this);
        this.setOTPCookie = this.setOTPCookie.bind(this);
    }

    updateOTPToken(e){
        this.setState({otpToken: e.target.value});
    }

    setOTPCookie(){
        if(this.state.otpToken !== ''){
            document.cookie = `OTP=${this.state.otpToken};max-age=604800;path=/;domain=.farrelltv.org;secure`
            window.location.href = '/shows/'
        }
    }

    render() {
        return (
            <div style={{marginTop: "3em", marginBottom: "3em"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Login with One Time Token</h2>
                            <hr />
                            <AlertMessage variant="warning" message={"Note: This token can be used only one time (as the name implies) and is only good for 12 hours or until you clear your browser cache/cookies -- whichever comes first. It is not recommended to use \"Incognito Mode\" or \"Private Browsing\" mode if you wish to watch multiple videos."} />
                            <form>
                                Please enter the Token (OTP) Provided to you:
                                <div className="form-group">
                                    <input type="text" className="form-control" id="otpToken"
                                           placeholder="OTP Token" onChange={this.updateOTPToken}
                                           value={this.state.otpToken}
                                    />
                                    <br />
                                    <button type="button" className="btn btn-primary" onClick={this.setOTPCookie}>Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

}

export default Login;