import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/custom.css";
import 'video.js/dist/video-js.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

ReactDOM.render(
<App/>,
    document.getElementById("root")
);