import React, { Component } from 'react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';


export class ControlledEditor extends Component {
    constructor(props) {
        super(props);
        if (this.props.defaultContent !== undefined) {
            this.state = {
                editorState: EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(this.props.defaultContent)
                    )
                ),
            };
        } else {
            this.state = {
                editorState: EditorState.createEmpty()
            };
        }
        this.props.updateContentState(this.state.editorState.getCurrentContent());
    }

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState,
        });
        this.props.updateContentState(editorState.getCurrentContent());
    };

    render() {
        const { editorState } = this.state;
        return (
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                editorStyle={{ border: "1px solid hex(#495057)" }}
            />
        )
    }
}
