import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ModifyShowButtonRenderer from './components/admin/ModifyShowButtonRenderer'
import {apiGatewayEndpoint} from "./config";

declare function sendExceptionCWR(error: Error, fileName: string, lineNum: number): void;

class Admin extends Component {

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            showTable: {
                columnDefs: [
                    {headerName: "Show ID", field: "id", resizable: true},
                    {headerName: "Description", field: "shortDescription", resizable: true},
                    {headerName: "Air Date", field: "airDate", resizable: true},
                    {headerName: "Video URL", field: "videoURL", resizable: true},
                    {headerName: "Thumbnail URL", field: "videoThumbnail", resizable: true},
                    {headerName: "Status", field: "status", resizable: true},
                    {headerName: "Modify", field: "update", cellRenderer: "btnCellRenderer"}
                ],
                rowData: [
                ],
                frameworkComponents: {
                    btnCellRenderer: ModifyShowButtonRenderer
                }
            },
            jobsTable: {
                columnDefs: [
                    {headerName: "Submit Time", field: "timing.SubmitTimeMillis", resizable: true,
                        cellRenderer: (data) => {
                            return data.value ? (new Date(data.value)).toLocaleString() : '';
                    }},
                    {headerName: "Input Key", field: "input.Key", resizable: true},
                    {headerName: "Output Key", field: "output.Key", resizable: true},
                    {headerName: "Output Key Prefix", field: "outputKeyPrefix", resizable: true},
                    {headerName: "Status", field: "output.Status", resizable: true},
                    {headerName: "Status", field: "output.StatusDetail", resizable: true}
                ],
                rowData: [
                ]
            }

        }
        this.fetchShows = this.fetchShows.bind(this);
        this.fetchJobs = this.fetchJobs.bind(this);
        this.fetchShows();
        this.fetchJobs();
    };

    fetchShows = () => {
        fetch(`${apiGatewayEndpoint}/shows`)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    showTable: {
                        ...this.state.showTable,
                        rowData: result
                    }
                })
            })
            .catch(e => {
                e.message = `Error when fetching all shows: ${e.message}`
                console.error(e);
                // eslint-disable-next-line no-restricted-globals
                sendExceptionCWR(e, location.pathname, 55);
            });
    };

    fetchJobs = () => {
        fetch(`${apiGatewayEndpoint}/jobs`, {credentials: 'include'})
            .then(response => response.json())
            .then(result => {
                this.setState({
                    jobsTable: {
                        ...this.state.jobsTable,
                        rowData: result
                    }
                })
            })
            .catch(e => {
                e.message = `Error when fetching all jobs: ${e.message}`
                console.error(e);
                // eslint-disable-next-line no-restricted-globals
                sendExceptionCWR(e, location.pathname, 74);
            });
    };




    render() {
        return(
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" style={{marginTop: "3em", marginBottom: "3em"}}>
                        <div>
                            <h2>Videos</h2>
                            <hr />
                        </div>
                        <div
                            className="ag-theme-balham"
                            style={{ height: '40em'}}
                        >
                            <AgGridReact
                                columnDefs={this.state.showTable.columnDefs}
                                rowData={this.state.showTable.rowData}
                                frameworkComponents={this.state.showTable.frameworkComponents}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" style={{marginTop: "3em", marginBottom: "3em"}}>
                        <div>
                            <h2>Jobs</h2>
                            <hr />
                        </div>
                        <div
                            className="ag-theme-balham"
                            style={{ height: '40em'}}
                        >
                            <AgGridReact
                                columnDefs={this.state.jobsTable.columnDefs}
                                rowData={this.state.jobsTable.rowData}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}



export default Admin;