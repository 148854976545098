import React, { Component } from "react";
import AlertMessage from "./components/AlertMessage";
import OTPDescription from "./components/admin/OTPDescription";
import {apiGatewayEndpoint} from "./config";

declare function sendExceptionCWR(error: Error, fileName: string, lineNum: number): void;

class OTPAdmin extends Component {

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            description: '',
            otp: ''
        }
        this.updateDescription = this.updateDescription.bind(this);
        this.generateOTP = this.generateOTP.bind(this);
    }

    updateDescription(e){
        this.setState({description: e.target.value});
    }

    generateOTP(){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({description: this.state.description}),
            credentials: 'include'
        }
        fetch(`${apiGatewayEndpoint}/otp`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    otp: result.ddb_id
                })
            })
            .catch(e => {
                e.message = `Error when generating OTP: ${e.message}`
                console.error(e);
                // eslint-disable-next-line no-restricted-globals
                sendExceptionCWR(e, location.pathname, 30);
        });
    }

    render() {
        return (
            <div style={{marginTop: "3em", marginBottom: "3em"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Generate One Time Token</h2>
                            <hr />
                            <AlertMessage variant="warning" message={"Note: This token can be used only one time (as the name implies) and is only good for 12 hours or until you clear your browser cache/cookies -- whichever comes first. It is not recommended to use \"Incognito Mode\" or \"Private Browsing\" mode if you wish to watch multiple videos."} />
                            <form>
                                Please enter a description to be associated with the token:
                                <div className="form-group">
                                    <input type="text" className="form-control" id="otpDescription"
                                           placeholder="Description" onChange={this.updateDescription}
                                           value={this.state.description}
                                    />
                                    <br />
                                    <button type="button" className="btn btn-primary" onClick={this.generateOTP}>Generate</button>
                                </div>
                            </form>
                            <OTPDescription otp={this.state.otp} description={this.state.description} />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

}

export default OTPAdmin;