import React, { Component } from "react";
import Gallery from 'react-grid-gallery';
import {imageList} from "./configs/gallery";

class PhotoGallery extends Component {
    render() {
        document.title = "Gallery";
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" style={{marginTop: "3em", marginBottom: "3em"}}>
                        <div>
                            <h2>Photo Gallery</h2>
                            <hr />
                        </div>
                        <div>
                            <Gallery images={imageList.sort((a, b) => b.src.localeCompare(a.src))} enableImageSelection={false}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PhotoGallery;