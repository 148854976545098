import React, {Component} from "react";
import VideoPlayer from "./components/VideoPlayer";
import sanitizeHtml from 'sanitize-html';
import ShowCardList from "./components/show-cards/show-card-list";
import {apiGatewayEndpoint, videoStorageEndpoint} from "./config";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserLock} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";

declare function sendExceptionCWR(error: Error, fileName: string, lineNum: number): void;

export default class VideoPage extends Component {

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            showDetails: "",
            error: false,
            notFound: true
        }
        this.fetchShowDetails = this.fetchShowDetails.bind(this);
    }

    fetchShowDetails = () => {
        let error = false;
        let notFound = false;
        let showID = this.props.match.params.videoID;
        if (showID) {
            axios ({
                method: "get",
                url: `${apiGatewayEndpoint}/shows/${showID}`
            }).then(response => {
                return response.data;
            }).then(result => {
                result.description = sanitizeHtml(result.description, {
                    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'hr', 'u', 'li', 'ul', 'ol'],
                })
                this.setState({showDetails: result})
            }).catch(e => {
                if (e.response){
                    error = true
                    if (e.response.status === 404) notFound = true;
                }
                e.message = `Unable to fetch show ${showID}: ${e.message}`;
                // eslint-disable-next-line no-restricted-globals
                sendExceptionCWR(e, location.pathname, 30);
                this.setState({error: error, notFound: notFound});
            });
        }
    };

    componentDidMount() {
        this.fetchShowDetails();
    }

    render() {
        if (this.props.match.params.videoID === undefined){
            document.title = "Recently Aired Episodes";
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" style={{marginTop: "3em", marginBottom: "3em"}}>
                            <div>
                                <h2>Recently Aired Episodes</h2>
                                <small><i>Some episodes are restricted and require a valid Monsignor Farrell Office365 Account to access. These restricted videos are marked with a <FontAwesomeIcon icon={faUserLock} /> icon.</i></small>
                                <hr/>
                            </div>
                            <div>
                                <ShowCardList />
                            </div>

                        </div>
                    </div>
                </div>
            );
        } else if (this.props.match.url.startsWith("/videos") && this.state.showDetails !== ""
            && (this.state.showDetails.public === undefined || this.state.showDetails.public === false)) {
                window.location.href = `/shows/${this.props.match.params.videoID}`;
        } else if (this.state.showDetails.title !== undefined) {
            document.title = this.state.showDetails.title;
            const videoJsOptions = {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: `${videoStorageEndpoint}/${this.state.showDetails.videoURL}`,
                        type: "application/x-mpegURL",
                        withCredentials: true
                    },
                ],
                fluid: true,
                poster: `${videoStorageEndpoint}/${this.state.showDetails.videoThumbnail}`
            };
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h1 className="mt-4">{this.state.showDetails.title}</h1>
                            <hr />
                            <VideoPlayer {...videoJsOptions} />
                            <hr />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.showDetails.description
                                }} />
                        </div>
                        <div className="col-md-4">
                            <div className="card my-4">
                                <h5 className="card-header">Information</h5>
                                <div className="card-body">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Air Date:</td>
                                            <td>{this.state.showDetails.airDate}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" style={{marginTop: "3em", marginBottom: "3em"}}>
                            <div>
                                { this.state.error ? "The video you requested could not be loaded. Please select another video." : "Loading show. Please stand by." }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}