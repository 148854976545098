import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css";
import {apiGatewayEndpoint} from "../../config";
import AlertMessage from "../AlertMessage";

declare function sendExceptionCWR(error: Error, fileName: string, lineNum: number): void;

class ModifyShowButtonRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.updateShow = this.updateShow.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleUpdateShowInfo = this.handleUpdateShowInfo.bind(this);
        this.state = {
            modalOpen: false,
            showInfo: "",
            showID: "",
            alert: {
                variant: "",
                message: ""
            },
        }
        this.prevState = this.state;
    }
    btnClickedHandler() {
        this.setState({
            modalOpen: true,
            showInfo: JSON.stringify(this.props.data),
            showID: this.props.data.id
        });
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modalOpen: !prevState.modalOpen,
        }));
        this.setState(this.prevState);
    };

    handleUpdateShowInfo(e){
        this.setState({
            showInfo: e.target.value
        });
    }

    render() {
        return (
            <div>
                <button onClick={this.btnClickedHandler}>Update</button>
                <Modal show={this.state.modalOpen} onHide={this.toggleModal}>
                    <Modal.Header>
                        <Modal.Title>Modify Show {this.state.showID}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AlertMessage {...this.state.alert} />
                        <textarea style = {{ width: "100%", height: "30em"}} value={this.state.showInfo} onChange={this.handleUpdateShowInfo}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.updateShow("update")}>Update</Button>
                        <Button variant="danger" onClick={() => this.updateShow("delete")}>Delete</Button>
                        <Button variant="secondary" onClick={this.toggleModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    updateShow(modification){
        let fetchInit = {}
        if (modification === "update") {
            fetchInit = {
                method: "PATCH",
                body: this.state.showInfo,
                credentials: 'include'
            }
        } else if (modification === "delete") {
            fetchInit = {
                method: "DELETE",
                credentials: 'include'
            }
        } else {
            return null;
        }

        let a = window.confirm(`Are you sure you want to ${modification} the show?`);
        if (a) {
            let error = false;
            let responseCode = -1;
            this.setState({
                alert:{
                    variant: "info",
                    message: `Modifying Show ${this.state.showID}. Please do not close this modal.`
                }
            });
            fetch(`${apiGatewayEndpoint}/shows/${this.state.showID}`,
                    fetchInit)
                .then(response => {
                    if(!response.ok) error = true;
                    responseCode = response.status;
                })
                .then(result => {
                    if(error) {
                        let e = new Error ();
                        e.message = `HTTP/${responseCode} when ${modification} show ${this.state.showID}: ${JSON.stringify(result.error)}`
                        // eslint-disable-next-line no-restricted-globals
                        sendExceptionCWR(e, location.pathname, 131);
                        throw(e);
                    }
                    this.setState({
                        alert:{
                            variant: "success",
                            message: `Show ${this.state.showID} was successfully modified. Please refresh this page.`
                        }
                    });
                })
                .catch(e => {
                    e.message = `Exception when ${modification} show ${this.state.showID}: ${e.message}`
                    // eslint-disable-next-line no-restricted-globals
                    sendExceptionCWR(e, location.pathname, 131);
                    this.setState({
                        alert:{
                            variant: "danger",
                            message: `An error occurred while deleting show ${this.state.showID}: ${e}`
                        }
                    });
                });
        }
    }
}

export default ModifyShowButtonRenderer;