import Alert from 'react-bootstrap/Alert'
import React, { Component } from "react";

export default class AlertMessage extends Component{

    render() {
        if (this.props.message !== ""){
            return (
                <Alert variant={this.props.variant}>
                    {this.props.message}
                </Alert>
            );
        } else {
            return null;
        }
    };
}