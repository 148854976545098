import React, { Component } from "react";

export default class OTPDescription extends Component{

    render() {
        if (this.props.otp  && this.props.description ){
            return (
                <div>
                    <hr />
                    <p>This is the generated one time password. You can copy and paste this into an email to send to a user who does not have a valid Farrell Office365 Account.</p>
                    <dl className="row">
                        <dt className="col-sm-3">OTP</dt>
                        <dd className="col-sm-9">{this.props.otp}</dd>

                        <dt className="col-sm-3">Description</dt>
                        <dd className="col-sm-9">{this.props.description}</dd>

                        <dt className="col-sm-3">Link</dt>
                        <dd className="col-sm-9">{`https://farrelltv.org/login?otp=${this.props.otp}`}</dd>
                    </dl>
                </div>
            );
        } else {
            return null;
        }
    };
}