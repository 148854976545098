import React, {Component} from "react";
import {
    Route,
    BrowserRouter,
    Switch
} from "react-router-dom";
import fbn_logo from "./images/FBN_Logo.svg";
import Home from "./home";
import VideoPage from "./video-page"
import Upload from "./upload"
import Admin from "./admin"
import PhotoGallery from "./gallery"
import Login from "./login";
import OTPAdmin from "./otpadmin";
import ReleaseModal from "./components/ReleaseModal";
import ScrollToTop from "./components/ScrollToTop";
import News from "./news";
// import GoogleAnalytics from "./components/GoogleAnalytics";

declare function sendExceptionCWR(error: Error, fileName: string, lineNum: number): void;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = { showNav: false, releaseModalOpen: false };
        this.toggleNav = this.toggleNav.bind(this);
        this.setReleaseModalState = this.setReleaseModalState.bind(this);
    }

    toggleNav() {
        this.setState({
            showNav: !this.state.showNav
        })
    }

    setReleaseModalState(isOpen){
        this.setState({
            releaseModalOpen: isOpen
        });
    }

    componentDidCatch(error, info) {
        console.error(error);
        // eslint-disable-next-line no-restricted-globals
        sendExceptionCWR(error, location.pathname, 43);
    }

    render() {
        const show = (this.state.showNav) ? "show" : "" ;
        return (
            <BrowserRouter>
                <ReleaseModal isOpen={this.state.releaseModalOpen} setState={this.setReleaseModalState} />
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-custom fixed-top">
                    <div className="container">
                        <a className="navbar-brand nav-link" href="/" style={{padding: "0px"}}>
                            <div>
                                <img src={fbn_logo} height={"60vh"}
                                     className="d-inline-block align-middle" alt="" />
                            </div>
                        </a>
                        <button className="navbar-toggler" type="button" onClick={this.toggleNav}>
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className={"collapse navbar-collapse expanded-navbar " + show}>
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a href="/" className="nav-link">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/videos/" className="nav-link">Videos</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/gallery/" className="nav-link">Photo Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/news/" className="nav-link">News</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <div className="content">
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/gallery" component={PhotoGallery} />
                        <Route
                            path="/videos/:videoID?/"
                            render={(props) => <VideoPage {...props} />} />
                        <Route
                            path="/shows/:videoID?/"
                            render={(props) => <VideoPage {...props} />} />
                        <Route exact path="/admin/upload" component={Upload} />
                        <Route exact path="/admin/aws" component={Admin} />
                        <Route exact path="/admin/otp" component={OTPAdmin} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/news" component={News} />
                        <Route component={Home} />
                    </Switch>
                </div>

                <footer className="py-5 bg-dark">
                    <div className="container" onClick={() => {this.setReleaseModalState(true)}} >
                        <p className="m-0 text-center text-white">Copyright &copy; 2022 Monsignor Farrell High School & Farrell Television</p>
                    </div>
                </footer>

            </BrowserRouter>
    );
    }
    }

    export default App;