import React, { Component } from "react";
import {videoStorageEndpoint} from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'

export default class ShowCard extends Component {

    render() {
        const videoIsPublic = this.props.info.public !== undefined && this.props.info.public === true
        const path = videoIsPublic ? "videos" : "shows";
        return(
            <div className="card h-100">
                <img className="card-img-top" src={`${videoStorageEndpoint}/${this.props.info.videoThumbnail}`} alt="" />
                <div className="card-body">
                    <h4 className="card-title">{this.props.info.title}</h4>
                    <small className="form-text text-muted card-sub-title"> {!videoIsPublic ? <FontAwesomeIcon icon={faUserLock} /> : null} Aired on {this.props.info.airDate}  </small>
                    <hr />
                    <p className="card-text">{this.props.info.shortDescription}</p>
                </div>
                <div className="card-footer" style={{textAlign: "center"}}>
                    <a href={`/${path}/${this.props.info.id}`} className="btn btn-primary">Watch</a>
                </div>
            </div>
        );
    }
}