import React, { Component } from "react";

export default class ArticleCard extends Component {

    render() {
        return(
            <div className="card h-100">
                <div className="card-body">
                    <a target="_blank" rel="noopener noreferrer" href={this.props.info.link}> <h4 className="news-card-title">{this.props.info.title}</h4> </a>
                    <small className="form-text text-muted card-sub-title"> Published {this.props.info.publishedDate}</small>
                    <small className="form-text text-muted card-sub-title"><i>{this.props.info.source}</i></small>
                </div>
            </div>
        );
    }
}