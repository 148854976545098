export const articleList = [
    {
        "title": "After an 18-month hiatus, Farrell TV wraps up season with celebs like Casey Jost and Joe Imborgio of the Impractical Jokers",
        "publishedDate": "2022-07-15",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/entertainment/2022/07/after-an-18-month-hiatus-farrell-tv-wraps-up-season-with-celebs-like-casey-jost-and-joe-imborgio-of-the-impractical-jokers.html"
    },
    {
        "title": "Farrell anchor interviews MLK relative; alum meets Oprah on set",
        "publishedDate": "2015-04-30",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/inside_out_column/2015/04/farrell_students_interview_niece_of_dr_martin_luther_king.html"
    },
    {
        "title": "Monsignor Farrell students get sit-down with NYPD Commissioner William Bratton",
        "publishedDate": "2015-06-10",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/eastshore/2015/06/monsignor_farrell_students_get.html"
    },
    {
        "title": "20 Farrell students get thumbs up from Pope Francis",
        "publishedDate": "2015-09-28",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/inside_out_column/2015/09/20_farrell_students_get_thumbs_up_from_pope_francis.html"
    },
    {
        "title": "Martha Stewart and Al Roker serve cake to Farrell students",
        "publishedDate": "2017-01-27",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/inside_out_column/2017/01/farrell_students.html"
    },
    {
        "title": "Film featuring Farrell students premieres on Amazon Video",
        "publishedDate": "2018-01-05",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/entertainment/2018/01/hollywood_filmmaker_from_state.html"
    },
    {
        "title": "Farrell & Hill students learn from Harry Connick Jr., Megyn Kelly",
        "publishedDate": "2018-05-09",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/entertainment/2018/05/farrell_hill_students_learn_from_harry_connick_jr_megyn_kelly.html"
    },
    {
        "title": "HGTV's 'Love It or List It' star Eric Eremita makes an appearance on Farrell's WFBN-TV station",
        "publishedDate": "2019-02-08",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/life-and-culture/g66l-2019/02/28c3aeec6a4726/hgtvs-love-it-or-list-it-star-eric-eremita-makes-an-appearance-on-farrells-wfbntv-station.html"
    },
    {
        "title": "Staten Island Nightlife: Annual Minty Awards Dinner Gala, another smashing success at the Hilton ",
        "publishedDate": "2020-01-18",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/life-and-culture/j66j-2020/01/7b456247e26977/staten-island-nightlife-annual-minty-awards-dinner-gala-another-smashing-success-at-the-hilton-.html"
    },
    {
        "title": "Fledgling Farrell high school broadcasters get good vibe from Regis Philbin",
        "publishedDate": "2010-10-14",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/inside_out_column/2010/10/fledgling_farrell_high_school_broadcasters_get_good_vibe_from_regis_philbin.html"
    },
    {
        "title": "Staten Island students visit Regis Philbin",
        "publishedDate": "2013-10-01",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/inside_out_column/2013/10/staten_island_students_visit_regis_philbin.html"
    },
    {
        "title": "Farrell students extend birthday wishes to Regis - in person",
        "publishedDate": "2011-09-06",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/inside_out_column/2011/09/farrell_students_extend_birthday_wishes_to_regis_in_person.html"
    },
    {
        "title": "Farrell TV has come a long way in 15 years",
        "publishedDate": "2013-06-16",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/inside_out_column/2013/06/farrell_tv_has_come_a_long_way_in_15_years.html"
    },
    {
        "title": "Farrell TV station marks 21st anniversary with visits to 'Good Morning America,' $100,000 Pyramid and Fox News",
        "publishedDate": "2019-05-24",
        "source": "Staten Island Advance Online",
        "link": "https://www.silive.com/life-and-culture/g66l-2019/05/e546f137f34348/farrell-tv-station-marks-21st-anniversary-with-visits-to-good-morning-america-100000-pyramid-and-fox-news-.html"
    }
]